@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root {
  --foreground-rgb: 50, 60, 70;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
} */

:root {
  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 50, 60, 70;
  --background-end-rgb: 50, 60, 70;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 50, 60, 70;
    --background-end-rgb: 50, 60, 70;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

.main-text-color {
  color: rgb(221, 199, 161);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.main-text-color-medium {
  color: rgb(221, 199, 161);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.main-text-color-small {
  color: rgb(221, 199, 161);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 13px;
  font-weight: 400;
}

.autocomplete-font {
  color: rgb(221, 199, 161);
  background-color: rgb(40,40,42);
}

.axis-style line{
  stroke: rgb(221, 199, 161);
}

.axis-style path{
  stroke: rgb(221, 199, 161);
}

.axis-style text{
  fill: rgb(221, 199, 161);
}